const breakpoints = [0, 480, 768, 1024, 1248, 1366, 1920]
const scaleNames = ['xs', 'sm', 'm', 'l', 'll', 'xl', 'hd']

export const above = breakpoints.reduce((acc, bp, index) => {
	return acc 
		? acc = { ...acc, [`${scaleNames[index]}`]: `@media (min-width: ${bp}px)` } 
		: acc
}, {})

export const below = breakpoints.reduce((acc, bp, index) => {
	return acc 
		? acc = { ...acc, [`${scaleNames[index]}`]: `@media (max-width: ${bp}px)` } 
		: acc
}, {})

export const between = (breakpointA, breakpointB) => {
  return `@media (min-width: ${breakpointA}) and (max-width: ${breakpointB})`
}