import SimpleLayout from "../../layout/SimpleLayout"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/react-splide/css"
import Button from "@mkt/ui/components/Ccbr/Button"
import Img from "@mkt/ui/components/common/Img"
import useWidgetCards from "./hooks"
import * as S from "./styles"

const WidgetCards = ({ pageContext, location }) => {
  const { title, btnText, selectedProducts, onClickButton, showPagination } =
    useWidgetCards({
      location,
      pageContext,
    })

  return (
    <SimpleLayout>
      <div css={S.Widget}>
        <h1 css={S.Title}>{title}</h1>
        <Splide
          options={{
            rewind: true,
            focus: "center",
            perPage: 1,
            arrows: false,
            fixedWidth: "375px",
            gap: "48px",
            pagination: showPagination,
          }}
          aria-label={title}
        >
          {selectedProducts?.map(({ slug, content }, index) => (
            <SplideSlide>
              <div css={S.SuggestedCardsBox} key={`card-featured-box-${index}`}>
                <div css={S.BoxImg}>
                  <Img
                    src={content?.image?.filename}
                    alt={content?.title}
                    width="274px"
                    height="174px"
                  />
                </div>
                <h2 css={S.BoxTitle}>{content?.title}</h2>
                <Button
                  type="large"
                  htmlType="button"
                  css={S.BoxButton}
                  data-slug={slug}
                  onClick={onClickButton}
                >
                  {btnText}
                </Button>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </SimpleLayout>
  )
}

export default WidgetCards
