import { css } from "@emotion/react"
import { above, below } from "@mkt/ui/styles/Breakpoints"
import { animeShow } from "@mkt/ui/styles/Keyframes"

export const Widget = css`
  background: #f3f3f4;
  overflow: hidden;
  width: 100%;
  height: 600px;
  position: relative;
  text-align: center;
  font-family: Muli, Open Sans, sans-serif;

  ${above["m"]} {
    padding: 62px 0px;
  }

  ${below["sm"]} {
    padding: 40px 0px;
  }
  .splide {
    &__pagination {
      position: relative;
      bottom: 0;
      &__page {
        width: 12px;
        height: 12px;
        background-color: #d8d8d8;
        &.is-active {
          background-color: #5473e3;
          transform: scale(1);
        }
      }
    }
  }
`

export const Title = css`
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 40px;
`

export const SuggestedCardsBox = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${animeShow} 0.59s cubic-bezier(0.69, 0.01, 0.25, 1) 0.5s;
  background-color: white;
  border: 2px solid #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px 2px rgba(198, 195, 195, 0.35);
  margin-bottom: 24px;
  color: black;
  cursor: default;
  min-height: 384px;
  padding: 40px 50px;
  text-align: center;
  transition: box-shadow 0.25s ease;
  width: 100%;

  // ${below["l"]} {
  //   min-width: 20%;
  //   max-width: 35%;
  // }

  ${below["m"]} {
    min-width: 100%;
    max-width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
`

export const BoxTitle = css`
  width: 100%;
  min-height: 52px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0;
  margin: 0 0 32px 0;
`
export const BoxImg = css`
  width: 274px;
  height: 174px;
  margin-bottom: 24px;

  & img {
    width: 100%;
    height: auto;
  }
`
export const BoxButton = css`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 100%;

  span {
    display: block;
    font-weight: 700;
    font-size: 18px;
    font-weight: 700;
  }
`
