import { useEffect, useState } from "react"
import getUrlParams from "@mkt/utils/getUrlParams"
import { useStaticQuery, graphql } from "gatsby"

const useWidgetCards = ({ pageContext }) => {
  const [title, setTitle] = useState()
  const [btnText, setBtnText] = useState()
  const [slugCards, setSlugCards] = useState()
  const [arrSlugsCards, setArrSlugsCards] = useState([])

  useEffect(() => {
    setTitle(getUrlParams().get("title"))
    setBtnText(getUrlParams().get("button"))
    setSlugCards(getUrlParams().get("cards"))
  }, [])

  useEffect(() => {
    setArrSlugsCards(slugCards?.split(","))
  }, [slugCards])

  const formatContent = (card) => ({
    ...card?.node,
    content: JSON.parse(card?.node?.content),
  })
  const products = pageContext.allProductsCards.map(formatContent)
  const filterBySlugCard = (card) => arrSlugsCards?.includes(card.slug)
  const selectedProducts = products?.filter(filterBySlugCard)

  const onClickButton = (e) => {
    const { slug } = e.target.dataset
    top.location.href = `/${slug}`
  }

  const cardsQty = selectedProducts.length
  const showPagination = cardsQty > 1 ? true : false

  return {
    title,
    btnText,
    selectedProducts,
    onClickButton,
    showPagination,
  }
}

export default useWidgetCards
