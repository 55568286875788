const colors = {
  gray: {
    default: "#656565",
    medium: "#6F6F6F",
    medium_c99: "#999999",
    medium_c8e: "#8e8e8e",
    light: "#E1E1E1",
    lightest: "#F9F9F9",
    dark: "#1c1c1c",
    gray_60: "#444444",
  },
  pink: "#b542b8",
  black: "#000",
  white: "#fff",
  red: "#FF4747",
  orange: "#FBA925",
  blue: {
    default: "#5473e3",
    dark: "#01006A",
    light: "#12C8F9",
  },
  green: {
    default: "#43CA4D",
    light: "#68E319",
    dark: "#2C6F8F",
  },
}

const fonts = {
  fontSize: {
    default: "16px",
    sm: "13px",
    lg: "18px",
    xl: "22px",
  },
  fontWeight: {
    normal: "400",
    bold: "700",
    extrabold: "900",
  },
  fontFamily: {
    opensans: ['"open san"', "helvetica", "arial", "sans-serif"],
  },
}

export { colors, fonts }
